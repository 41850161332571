<template>

<div class="search">

	<div class="search-filter">

		<div class="search-filter-title" v-if="isSpecialists">Search by speciality, keyword or location</div>
		<div class="search-filter-title" v-if="isCentres">Search for centres of excellence</div>

		<div class="search-filter-inputs">

			<com-select v-if="isCentres" v-model="filter.region" :input="{ label: 'Select country', options: block.regions }" />
			<com-select v-if="isSpecialists" v-model="filter.area" :input="{ label: 'Select speciality', options: block.areas }" />
			<com-textbox v-if="isSpecialists" v-model="filter.keyword" :input="{ label: 'Keyword', suggestions: block.keywords }" />
			<com-textbox v-model="filter.location" :input="{ label: 'Postcode / zip' }" />

		</div>

	</div>

	<div class="search-directory is-centres" v-if="isCentres">

		<div class="search-directory-item" v-for="item in directory" :key="item.id">

			<div class="search-directory-item-info">

				<div class="search-directory-item-info-head">

					<a :href="'mailto:' + item.email" class="search-directory-item-info-head-name">{{ item.name }}</a>

					<div class="search-directory-item-info-head-distance" v-if="filter.location"><span>Distance</span> {{ item.distance }} km</div>

				</div>

				<div class="search-directory-item-info-body">

					<div class="search-directory-item-info-body-location">{{ item.location }}</div>
					<div class="search-directory-item-info-body-email"><a :href="'mailto:' + item.email">{{ item.email }}</a></div>
					<div class="search-directory-item-info-body-phone">{{ item.phone }}</div>
					<div class="search-directory-item-info-body-website"><a :href="item.website" target="_blank">visit website</a></div>

				</div>

			</div>

		</div>

	</div>

	<div class="search-directory is-specialists" v-if="isSpecialists">

		<div class="search-directory-item" v-for="item in directory" :key="item.id">

			<div class="search-directory-item-image" v-bgimage="item.image" />

			<div class="search-directory-item-info">

				<div class="search-directory-item-info-head">

					<a :href="'mailto:' + item.email" class="search-directory-item-info-head-name">{{ item.name }}</a>

					<div class="search-directory-item-info-head-distance" v-if="filter.location"><span>Distance</span> {{ item.distance }} km</div>

				</div>

				<div class="search-directory-item-info-body">
					
					<div class="search-directory-item-info-body-areas">

						<div v-for="area in item.areas" :key="area">{{ areaName(area) }}</div>

					</div>

					<div class="search-directory-item-info-body-contact">

						<div class="search-directory-item-info-body-contact-location">{{ item.location }}</div>
						<div class="search-directory-item-info-body-contact-website"><a :href="item.website" target="_blank">visit website ></a></div>
						<div class="search-directory-item-info-body-contact-phone">Contact No. <span>{{ item.phone }}</span></div>

					</div>

				</div>

			</div>

		</div>

	</div>

</div>

</template>

<script>

import mixBlock from './mixin.js'

export default {

	mixins: [mixBlock],

	props: ['block'],

	components: {

		'com-textbox': () => import('./form/Textbox'),
		'com-select': () => import('./form/Select')

	},

	data: function() {

		return {
			api: 'directory',
			filter: {
				region: '',
				area: '',
				keyword: '',
				location: ''
			},
			directory: []
		}

	},

	watch: {

		filter: {

			deep: true,

			handler: function() {

				this.load()
				
			}

		}

	},

	computed: {

		isSpecialists: function() {

			return this.block.type === 1

		},

		isCentres: function() {

			return this.block.type === 2

		}

	},

	methods: {

		areaName: function(id) {

			return this.$_.find(this.block.areas, {
				id: parseInt(id)
			}).name

		},

		load: function() {

			this.$api.cancel(this.api)

			this.$api.get(this.api, {
				type: this.block.type,
				filter: this.filter,
				context: this.$store.getters['context'].id,
				region: this.$store.getters['language'].id,
			}, this.block.id).then(function(json) {

				this.directory = json.directory

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.search-filter {
	padding: 25px 15px 10px 15px;
	border-radius: 11px;
}

.is-aa .search-filter {
	background-color: #C21920;
}

.is-afa .search-filter {
	background-color: #5ca043;
}

.is-stars .search-filter {
	background-color: #1277d4;
}

.is-mobile .search-filter {
	padding: 15px 15px 0px 15px;
}

.search-filter-title {
	color: #fff;
	padding: 0px 35px;
	font-size: 14px;
	text-transform: uppercase;
	font-weight: 300;
	letter-spacing: 1px;
}

.is-mobile .search-filter-title {
	text-align: center;
	padding: 10px 0px;
}

.search-filter-inputs {
	display: flex;
	padding: 25px 8px 15px 8px;
}

.is-mobile .search-filter-inputs {
	flex-direction: column;
	padding: 10px;
}

.search-filter-inputs >>> div.textbox,
.search-filter-inputs >>> div.select {
	flex-grow: 1;
	margin: 0px 7px;
	text-transform: uppercase;
}

.is-mobile .search-filter-inputs >>> div.textbox,
.is-mobile .search-filter-inputs >>> div.select {
	margin: 0px 0px 10px 0px;
}

.search-filter-inputs >>> input,
.search-filter-inputs >>> select {
	text-transform: uppercase;
}

.search-directory {
	padding-top: 48px;
}

.search-directory-item {
	display: flex;
	border-bottom: 1px solid #F5F5F5;
	padding-bottom: 24px;
	margin-bottom: 24px;
}

.search-directory-item-image {
	width: 173px;
	height: 173px;
	background-position: 50% 50%;
	background-size: cover;
	border-radius: 50%;
	margin-right: 30px;
	flex-shrink: 0;
}

.is-tablet .search-directory-item-image {
	width: 145px;
	height: 145px;
	margin-right: 15px;
}

.is-mobile .search-directory-item-image {
	width: 90px;
	height: 90px;
	margin-right: 15px;
	position: absolute;
}

.search-directory-item-info {
	flex-grow: 1;
}

.search-directory-item-info-head {
	display: flex;
	justify-content: space-between;
	margin-bottom: 8px;
	padding: 0px 30px;
}

.is-mobile .is-specialists .search-directory-item-info-head {
	padding: 0px 15px 0px 110px;
	flex-direction: column;
	height: 90px;
	justify-content: center;
}

.search-directory-item-info-head-name {
	font-size: 24px;
	font-weight: bold;
	line-height: 30px;
	color: #1277D4;
	padding-right: 15px;
}

.is-centres .search-directory-item-info-head-name {
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAeBAMAAAC2z6FgAAAAJ1BMVEUAAAAcJEwbI0waIkwZIkwaI0saI0waJEwaJEwaI0wcIkwaI0waI0xO9IOOAAAADHRSTlMAQMB/b96dgNDwJZFgPsWQAAAAeUlEQVQoz2M4AwOHGKCAWKEjgoI9x4AYSeggA4POcSDGL3TM2DjmEBAfNYYCuPEIgFXohJLSnKNAfFIJDHrOYBgvQ1CIxcUFLIQAB1jOHCNaCOHtE4ICLLt3E2Ej16pVYCGEtw1IsxHhbQWW0DB8NhIWQgfECgliAABEwu8af8fiPQAAAABJRU5ErkJggg==');
	background-repeat: no-repeat;
	background-position: 0px 50%;
	background-size: 24px auto;
	padding-left: 48px;
}

.is-centres .search-directory-item-info-head-name,
.is-mobile .search-directory-item-info-head-name {
	font-size: 20px;
}

.search-directory-item-info-head-name:hover {
	text-decoration: underline;
}

.search-directory-item-info-head-distance {
	color: #343434;
	font-size: 24px;
	font-weight: bold;
}

.is-mobile .search-directory-item-info-head-distance {
	text-align: right;
	width: 100%;
}

.search-directory-item-info-head-distance span {
	color: #343434;
	font-size: 14px;
	font-style: italic;
	font-weight: 300;
	margin-right: 15px;
}

.search-directory-item-info-body {
	background-color: #F5F5F5;
	border-radius: 23px;
	padding: 15px;
	display: flex;
}

.is-tablet .is-specialists .search-directory-item-info-body,
.is-mobile .search-directory-item-info-body {
	flex-direction: column;
}

.is-tablet .is-centres .search-directory-item-info-body {
	flex-wrap: wrap;
}

.search-directory-item-info-body-areas {
	flex-grow: 1;
	background-color: #fff;
	border-radius: 11px;
	padding:7px;
	margin-right: 15px;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: flex-start;
}

.is-tablet .search-directory-item-info-body-areas,
.is-mobile .search-directory-item-info-body-areas {
	margin-right: 0px;
	margin-bottom: 15px;
}

.search-directory-item-info-body-areas div {
	color: #1A234C;
	font-weight: 300;
	font-size: 14px;
	margin: 8px;
	width: calc(50% - 16px);
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAZBAMAAAA/LDgqAAAAMFBMVEUAAAAaIkwgIFAaI0wbJUoaI0waI0saI0sbI00cJEwYIFAbI0wZIksbI0waI00aI0xXdEIXAAAAD3RSTlMA0BDwMKCw4GBAIMBwkFBEaj8BAAAAy0lEQVQY00WOMQrCQBBFv6IgQSwCFmIRg1ewsFvBC4gXsLdRbG1S2RqwsBYv4CmsPYoiJojFd2bDZh5hB+a9DQvg4IpMxt31cwgXkuUMZxnFCIgc9wmnHXLoGAMNDhBtuOIaPVcAx1KiNvkBcGWOzRfCigs5u3zAPQEJVKPJG+RTMijcqg+o1/s1HS78/wNj5mgwrvPkp+97hSDlTs4546DfMxmtEKScACFQXYq2INW1BVGiWwv80oLqkgUnry2gagsSr43Ua6O5RMUfM2hWGeka/FgAAAAASUVORK5CYII=');
	background-repeat: no-repeat;
	background-position: 0px 50%;
	background-size: 12px auto;
	padding-left: 20px;
}

.search-directory-item-info-body-contact {
	width: 340px;
	flex-shrink: 0;
	background-color: #fff;
	border-radius: 11px;
	padding: 15px;
}

.is-tablet .search-directory-item-info-body-contact,
.is-mobile .search-directory-item-info-body-contact {
	width: 100%;
}	

.search-directory-item-info-body-contact-location {
	color: #1A234C;
	font-weight: 300;
	font-size: 14px;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAeBAMAAAC2z6FgAAAAJ1BMVEUAAAAcJEwbI0waIkwZIkwaI0saI0waJEwaJEwaI0wcIkwaI0waI0xO9IOOAAAADHRSTlMAQMB/b96dgNDwJZFgPsWQAAAAeUlEQVQoz2M4AwOHGKCAWKEjgoI9x4AYSeggA4POcSDGL3TM2DjmEBAfNYYCuPEIgFXohJLSnKNAfFIJDHrOYBgvQ1CIxcUFLIQAB1jOHCNaCOHtE4ICLLt3E2Ej16pVYCGEtw1IsxHhbQWW0DB8NhIWQgfECgliAABEwu8af8fiPQAAAABJRU5ErkJggg==');
	background-repeat: no-repeat;
	background-position: 0px 50%;
	background-size: 12px auto;
	padding-left: 20px;
	margin-bottom: 10px;
}

.search-directory-item-info-body-contact-website {
	text-align: right;
	font-size: 12px;
	color: #1277D4;
	margin-bottom: 10px;
	font-weight: 300;
	text-transform: uppercase;
}

.search-directory-item-info-body-contact-website:hover {
	text-decoration: underline;
}

.search-directory-item-info-body-contact-phone {
	text-align: right;
	font-size: 14px;
	color: #1A234C;
	font-weight: 300;
}

.search-directory-item-info-body-contact-phone span {
	font-weight: bold;
}

.search-directory-item-info-body-location,
.search-directory-item-info-body-email,
.search-directory-item-info-body-phone,
.search-directory-item-info-body-website {
	width: 25%;
	background-color: #fff;
	border-radius: 11px;
	margin-right: 15px;
	padding: 15px 15px 15px 35px;
	background-position: 15px 50%;
	background-repeat: no-repeat;
	background-size: 12px auto;
}

.is-tablet .search-directory-item-info-body-location,
.is-tablet .search-directory-item-info-body-email,
.is-tablet .search-directory-item-info-body-phone,
.is-tablet .search-directory-item-info-body-website {
	width: calc(50% - 8px);
}

.is-tablet .search-directory-item-info-body-location,
.is-tablet .search-directory-item-info-body-email {
	margin-bottom: 15px;
}

.is-tablet .search-directory-item-info-body-email,
.is-tablet .search-directory-item-info-body-website {
	margin-right: 0px;
}

.is-mobile .search-directory-item-info-body-location,
.is-mobile .search-directory-item-info-body-email,
.is-mobile .search-directory-item-info-body-phone,
.is-mobile .search-directory-item-info-body-website {
	width: 100%;
	margin-right: 0px;
}

.is-mobile .search-directory-item-info-body-location,
.is-mobile .search-directory-item-info-body-email,
.is-mobile .search-directory-item-info-body-phone {
	width: 100%;
	margin-right: 0px;
	margin-bottom: 15px;
}

.search-directory-item-info-body-location {
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAsCAMAAAA6jerHAAAAP1BMVEUAAAAaI0wgIFAaI0wbI0wbJUocJEwaI0waI00aJEwYIFAaI00aI0saI0waI0saI0wbI0waI0saIkwZIksaI0y8chy5AAAAFHRSTlMA0BDAYDBA8KCAIFCwr5/gkN9/cLBCzAcAAAFnSURBVDjLhZPrwoMgCIY1zEPOWpv3f63fSA7usxp/lvAIL8hMb5MrYGu1sLrJnFuG2hn4E8QTcoet9cSe32JK89rkZ2Nmnywlmzqm+eKiriW2W0rBcf4nYd7QW/j41Du9TdDp8kd1c2KHCi/FtiGPaAVJNKuU/Mp0olimRJG9bq9ou6Pz43N4fFKik3uPla20+gt+T8ZhZ8oopdrdEUhUq035CQF/W8X3IQakS7Nh0hnF4/0g0sFYkTRrm0vAq+zcDUZon7qZPlhEiyuUKCDfCm1cY8zE5SwJ53PoNGUVjiN4d90tyFB3VDfSMHVOobxKqCIJ8UzPwkrUbPcsLVJoNZSykzwUcBHPWxAOJCTaG4nB1/L6lFLmpdtlKp4kjAaUSP4I68is3KR2ZaczBvqFH6lVfSPVMwE915Sr8pIjdc4oJX5lRoscUeaaQoY28ZpqjDPmjmLmmhLmzqTWPSXMPRXNb/OD5w83cCk7zu7EMwAAAABJRU5ErkJggg==');
}

.search-directory-item-info-body-email {
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAdBAMAAAAwW9POAAAALVBMVEUAAAAbI0wYJE0aJEwgIFAaI0saJEwcJEwaI0waI0saI0saI0wbI00YIFAaI0z+swmHAAAADnRSTlMAwD/QEJ+AQPDfsK9gIHStibgAAACQSURBVCjPvcqxCcJQFEbhv9BGbOysncE6CC7gBLZ2loKllVuIiwjuYRkUbN4MeZzc+0JuSJtTHj5dU+iuR1x/pUEj66vS0dXBz9xUBTNUs/YwQ1vWDGZoxxLMkNoFM2QLZsgXDOQL9vqAbMFyGZUFA5UFA5WVW65vb3XLm2xteuVVpVCtc1w/LeJ6SpdVr5Ma1qj9ewuB4awAAAAASUVORK5CYII=');
}

.search-directory-item-info-body-phone {
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAqCAMAAADoIdnnAAAAPFBMVEUAAAAaI0wgIFAaI0wbJUoaI0wbI00YIFAaI0scJEwZIksaI00aI0sbI0waJEwaIkwaI0wZIkwaJE0aI0xo2yx9AAAAE3RSTlMA8BCgMMBgILBAcFDgkNDPgG9Pf1Q8UAAAAVRJREFUOMuFldl2wyAMRNnNZjvp/P+/FgSUthhFT+T4omU0sQXFcVrAGsGG0qCQLGdyQbL3JR9DHbI8D+VQOLfHLKBbbQmpdlQAfD/GemETGjh+JQ4bLOOawwD3BqOaI87tFMDZDi9NU/hdUTt6TELcO+08ZG8L8nj33GvEkoV0KUHoZgldrPxhrb4JFyoUlWDWoLsYWQl2qaanPRksdAepi7WI0P2xkawzj+Egx7cXAT92avkp0vTo57Iqs2NEqtbGQGDLvuiQxsbMy637VZSlJ74VqQToZe7U3UHA/fMPT/+5k7LU8ERcJtmnhNcQQ/lK1dRRlrtmaY/GoPa7pUy5i/dkhtHd2stiHPfEpVLkWFR+4FbB9V/OZEdYEis3NTCy/rCAEk9c/iI/NIkr+uyCAlor0WPz1nMNmGE27rMTobXuIs6El+G+AfEiyDd1ODKEqeo3q7MX60EpX8YAAAAASUVORK5CYII=');
}

.search-directory-item-info-body-website {
	margin-right: 0px;
	background-color: #1A234C;
	color: #fff;
	text-align: center;
	padding: 15px;
	text-transform: uppercase;
	letter-spacing: 1px;
	border-radius: 23px;
}

.search-directory-item-info-body-email a:hover {
	text-decoration: underline;
}

</style>
